<template>
  <div class="head-container">
    <search-community ref="searchcommunity"></search-community>
    <el-input v-model="query.userName" clearable placeholder="输入客户昵称" style="width: 200px;" class="filter-item"
              @keyup.enter.native="toQuery"/>
    <el-button v-if="checkPermission(['ADMIN','SrCommunityIdInfo_ALL','SrCommunityIdInfo_SELECT'])" class="filter-item"
               size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索
    </el-button>
    <el-button class="filter-item" size="mini" @click="toClearInit">清空</el-button>
  </div>
</template>

<script>
  import searchCommunity from '@/components/common/searchCommunity'
  import checkPermission from '@/utils/permission' // 权限判断函数
  import eForm from './form'

  export default {
    components: {
      eForm,
      searchCommunity,
    },
    props: {
      query: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        queryTypeOptions: [
          { key: 'userName', display_name: '昵称' },
          { key: 'phone', display_name: '手机号码' }
        ]
      }
    },
    methods: {
      checkPermission,
      toQuery() {
        this.$parent.page = 0
        this.$parent.init()
      },
      toClearInit() {
        this.$parent.toClearInit()
      }
    }
  }
</script>
