<template>
  <div class="app-container card-info-list">
    <eHeader ref="header" :query="query"/>
    <div class="tab-box">
      <span class="tab-item"
        v-for="(item, index) in tabList"
           :key="index"
           :class="{active: status == item.value}"
           @click="tabHandle(item,index)"
      >{{item.label}}</span>
    </div>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="siteName" label="站点"/>
      <el-table-column prop="userName" label="客户昵称"/>
      <el-table-column prop="realName" label="姓名"/>
      <el-table-column prop="idNum" label="身份证号"/>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{scope.row.status== 1 ?'待审核' :'已审核'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="aduitTime" label="提交时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.aduitTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['ADMIN','SrCommunityIdInfo_ALL','SrCommunityIdInfo_EDIT']) && scope.row.status == 1"
            size="mini"
            type="success"
            @click="view(scope.row)"
          >审核</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
    <audit-dialog
      ref="detail"
      :row-data="currentRow"
    ></audit-dialog>
  </div>
</template>

<script>
  import auditDialog from '@/components/community/customer/cardinfo/form'
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  import { parseTime } from '@/utils/index'
  import eHeader from '@/components/community/customer/cardinfo/header'

  export default {
    name:'cardinfo',
    components: {
      eHeader,
      auditDialog,
    },
    mixins: [initData],
    data() {
      return {
        delLoading: false,
        sup_this: this,
        defaultImg: 'https://alita.oss-cn-shenzhen.aliyuncs.com/image/e1fa398899014ec2984bb2d5bf27e28b.png',
        belongUserId: '',
        lockDialogShow: false,
        isLock: false,

        currentRow: {},
        status: 0,
        tabList: [
          {
            label: '全部列表',
            value: 0,
          },
          {
            label: '待审核',
            value: 1,
          },
          {
            label: '已审核',
            value: 2,
          }
        ]
      }
    },
    mounted() {
      this.belongUserId = this.$route.query.belongUserId
      this.$nextTick(() => {
        this.init()
      })
    },
    methods: {
      parseTime,
      checkPermission,
      beforeInit() {
        this.url = '/community/crm/srCommunityIdInfo'
        const sort = 'id,desc'
        const query = this.query
        this.params = { page: this.page, size: this.size, sort: sort }
        const userName = query.userName
        if (userName !== '' && userName !== null) {
          this.params['userName'] = userName
        }
        if (this.$refs.header.$refs.searchcommunity.siteId) {
          this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
        }
        if(this.status){
          this.params.status = this.status;
        }
        return true
      },
      toClearInit() {
        this.query = {}
        this.init()
      },
      view(row) {
        const _this = this.$refs.detail;
        this.currentRow = row;
        _this.dialog = true;
      },
      tabHandle(item){
        this.status = item.value;
        this.init();
      }
    }
  }
</script>

<style lang="less" scoped>
.card-info-list {
  .tab-box {
    margin-bottom: 10px;
    .tab-item {
      display: inline-block;
      padding: 10px;
      background-color: #f5f7fa;
      border: 1px solid #e4e7ed;
      cursor: pointer;
      color: #909399;
      &:hover {
        color: #409eff;
      }
      &.active {
        color: #409eff;
        background-color: #ffffff;
        border-right-color: #dcdfe6;
        border-left-color: #dcdfe6;
      }
    }
  }
}
</style>
