<template>
<div class="defined-reaject">
  <el-select
    :disabled="definedDisabled"
    v-model="rejectReason"
    class="select-el"
    placeholder="请选择"
    multiple
    style="width: 360px"
  >
    <el-option
      v-for="(item,index) in rejectReasonOption"
      :label="item.message"
      :key="index"
      :value="item.message"
    >
    </el-option>
    <el-option
      label="自定义"
      value="自定义"
    >
    </el-option>
  </el-select>
  <div
    v-if="rejectReason.includes('自定义')"
    class="textarea-outer"
  >
    <el-input
      :disabled="definedDisabled"
      class="textarea-el"
      type="textarea"
      v-model="rejectReasonSelfDefined"
    ></el-input>
  </div>
</div>
</template>

<script>
  import { getReasonList } from '@/api/community/srCommunityIdInfo'
  export default {
    name: 'definedReaject',
    props: {
      definedDisabled: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        rejectReason: [],
        rejectReasonOption: [],
        rejectReasonSelfDefined: ''
      }
    },
    watch: {
      // 没有效果
      // rejectReasonBackfill(newValue) {
      //   console.log('aa')
      //   if(newValue){
      //     this.rejectReason = newValue;
      //   }
      // }
    },
    created(){
      this.getList();
    },
    methods: {
      getList() {
        getReasonList().then((res) => {
          console.log(res)
          this.rejectReasonOption = res;
        })
      },
      getTotalReason(){
        let totalReason = '';
        if(this.rejectReason.includes('自定义')){
          totalReason = this.deletedefined(this.rejectReason.join(';') + ';' + this.rejectReasonSelfDefined);
        }else {
          totalReason = this.rejectReason.join(';');
        }
        return totalReason;
      },
      deletedefined(string){
        return string.replace('自定义;','');
      }
    }
  }
</script>
<style lang="less">
  .defined-reaject {
    .select-el {
      width: auto;
    }
    .textarea-el {
      width: 360px;
    }
  }
</style>
<style lang="less" scoped>
.defined-reaject {
  .select-el {
    width: auto;
  }
  .textarea-outer {
    margin-left: 70px;
    margin-top: 10px;
  }

}
</style>
