<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="dialog"
    title="客户审核"
    width="600px"
    class="customer-audit"
  >
    <el-form
      ref="form"
      :model="form"
      size="small"
      :rules="rules"
    >
      <el-col :span="12">
        <el-form-item label="姓名">
          <span>{{form.realName}}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="证件类型">
          <span>{{form.idType==1 ? '身份证': '护照'}}</span>
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item label="证件号">
          <span>{{form.idNum}}</span>
        </el-form-item>
      </el-col>
      <el-row>
        <el-col
          :span="12">
          <el-form-item label="证件照">
            <el-image
              v-if="form.idFrontPic"
              class="id-img"
              :src="form.idFrontPic"
              :preview-src-list="[form.idFrontPic]"
            ></el-image>
            <span v-else>-</span>
          </el-form-item>
        </el-col>
        <el-col
          :span="12">
          <el-form-item label="证件照">
            <el-image
              v-if="form.idBackPic"
              class="id-img"
              :src="form.idBackPic"
              :preview-src-list="[form.idBackPic]"
            >
            </el-image>
            <span v-else>-</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :span="12">
          <el-form-item label="支付宝收款码">
            <el-image
              v-if="form.qrcodeAlipay"
              class="pay-img"
              :src="form.qrcodeAlipay"
              :preview-src-list="[form.qrcodeAlipay]"
            ></el-image>
            <span v-else>-</span>
          </el-form-item>
        </el-col>
        <el-col
          :span="12">
          <el-form-item label="微信收款码">
            <el-image
              v-if="form.qrcodeWechat"
              class="pay-img"
              :src="form.qrcodeWechat"
              :preview-src-list="[form.qrcodeWechat]"
            >
            </el-image>
            <span v-else>-</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :span="12">
          <el-form-item label="USDT二维码">
            <el-image
              v-if="form.qrcodeUsdt"
              class="usdt-img"
              :src="form.qrcodeUsdt"
              :preview-src-list="[form.qrcodeUsdt]"
            ></el-image>
            <span v-else>-</span>
          </el-form-item>
        </el-col>
        <el-col
          :span="12">
          <el-form-item label="USDT地址">
            <div>{{form.usdtAddress}}</div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        v-if="idAuditFlag"
        prop="identityStatus"
        label="实名审核"
      >
        <el-radio
          :disabled="idDisabled"
          v-model="form.identityStatus"
          :label="2"
        >通过</el-radio>
        <el-radio
          :disabled="idDisabled"
          v-model="form.identityStatus"
          :label="1"
        >拒绝</el-radio>
      </el-form-item>
      <el-form-item
        v-if="idAuditFlag && form.identityStatus == 1"
        label="拒绝原因"
      >
        <defined-reaject
          ref="idreason"
          :defined-disabled="idDisabled"
        ></defined-reaject>
      </el-form-item>
      <el-form-item
        v-if="alipayAuditFlag"
        prop="aliStatus"
        label="支付宝审核"
      >
        <el-radio
          :disabled="alipayDisabled"
          v-model="form.aliStatus"
          :label="2"
        >通过</el-radio>
        <el-radio
          :disabled="alipayDisabled"
          v-model="form.aliStatus"
          :label="1"
        >拒绝</el-radio>
      </el-form-item>
      <el-form-item
        v-if="alipayAuditFlag && form.aliStatus == 1"
        label="拒绝原因"
      >
        <defined-reaject
          ref="alipayreason"
          :defined-disabled="alipayDisabled"
        ></defined-reaject>
      </el-form-item>
      <el-form-item
        v-if="wechatAuditFlag"
        prop="wechatStatus"
        label="微信审核"
      >
        <el-radio
          :disabled="wechatDisabled"
          v-model="form.wechatStatus"
          :label="2"
        >通过</el-radio>
        <el-radio
          :disabled="wechatDisabled"
          v-model="form.wechatStatus"
          :label="1"
        >拒绝</el-radio>
      </el-form-item>
      <el-form-item
        v-if="wechatAuditFlag && form.wechatStatus == 1"
        label="拒绝原因"
      >
        <defined-reaject
          :defined-disabled="wechatDisabled"
          ref="wechatreason"
        ></defined-reaject>
      </el-form-item>
      <el-form-item
        v-if="usdtAuditFlag"
        prop="usdtStatus"
        label="USDT审核"
      >
        <el-radio
          :disabled="usdtDisabled"
          v-model="form.usdtStatus"
          :label="2"
        >通过</el-radio>
        <el-radio
          :disabled="usdtDisabled"
          v-model="form.usdtStatus"
          :label="1"
        >拒绝</el-radio>
      </el-form-item>
      <el-form-item
        v-if="usdtAuditFlag && form.usdtStatus == 1"
        label="拒绝原因"
      >
        <defined-reaject
          :defined-disabled="usdtDisabled"
          ref="usdtreason"
        ></defined-reaject>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button
        type="text"
        @click="cancel"
      >取消</el-button>
      <el-button
        :loading="loading"
        type="primary"
        @click="doSubmit"
      >确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import definedReaject from '@/components/community/customer/cardinfo/definedReaject'
import { aduit } from '@/api/community/srCommunityIdInfo'
export default {
    components:{
      definedReaject,
    },
  props: {
    rowData: {
      type: Object,
      default(){
        return {

        }
      }
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      rules:{
        identityStatus:[
          { required: true, message: '请选择实名通过还是拒绝',trigger: 'change' }
        ],
        aliStatus:[
          { required: true, message: '请选择支付宝审核通过还是拒绝',trigger: 'change' }
        ],
        wechatStatus:[
          { required: true, message: '请选择微信审核通过还是拒绝',trigger: 'change' }
        ],
        usdtStatus:[
          { required: true, message: '请选择USDT审核通过还是拒绝',trigger: 'change' }
        ]
      },
      form: {
        wechatStatus: '',
        identityStatus: '',
        aliStatus: '',
        usdtPic: '',
      },
      identityStatusInit:  '',
      aliStatusInit:  '',
      wechatStatusInit:  '',
      usdtStatusInit:  '',
    }
  },
  computed: {
    idAuditFlag() {
      return this.form.idNum;
    },
    alipayAuditFlag() {
      return this.form.qrcodeAlipay;
    },
    wechatAuditFlag() {
      return this.form.qrcodeWechat;
    },
    usdtAuditFlag() {
      return this.form.qrcodeUsdt;
    },
    idDisabled(){
      return this.identityStatusInit == 1 || this.identityStatusInit == 2;
    },
    alipayDisabled(){
      return this.aliStatusInit == 1 || this.aliStatusInit == 2;
    },
    wechatDisabled(){
      return this.wechatStatusInit == 1 || this.wechatStatusInit == 2;
    },
    usdtDisabled(){
      return this.usdtStatusInit == 1 || this.usdtStatusInit == 2;
    },
  },
  watch: {
    rowData(newValue, oldValue) {
      if(newValue){
        this.form = JSON.parse(JSON.stringify(newValue));
        if(newValue.identityStatus === 0){
          this.form.identityStatus = '';
        }
        if(newValue.aliStatus === 0){
          this.form.aliStatus = '';
        }
        if(newValue.wechatStatus === 0){
          this.form.wechatStatus = '';
        }
        this.identityStatusInit = newValue.identityStatus;
        this.aliStatusInit = newValue.aliStatus;
        this.wechatStatusInit = newValue.wechatStatus;
        this.usdtStatusInit = newValue.usdtStatus;
        if(newValue.identityReason){
          this.$nextTick(() => {
            this.$refs.idreason.rejectReason = newValue.identityReason.split(';')
          });
        }
        if(newValue.aliReason){
          this.$nextTick(() => {
            this.$refs.alipayreason.rejectReason = newValue.aliReason.split(';')
          })
        }
        if(newValue.wechatReason){
          this.$nextTick(() => {
            this.$refs.wechatreason.rejectReason = newValue.wechatReason.split(';')
          })
        }
        if(newValue.usdtReason){
          this.$nextTick(() => {
            this.$refs.usdtreason.rejectReason = newValue.usdtReason.split(';')
          })
        }
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    doSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid){
          let params = {
            userId: this.form.userId,
          }
          if(this.idAuditFlag && !this.idDisabled){
            params.identityStatus = this.form.identityStatus;
            if(this.form.identityStatus  == 1){
              if(!this.$refs.idreason.rejectReason.length){
                this.$message({
                  message: '请选择实名审核拒绝原因',
                  type: 'error',
                });
                return;
              }
              if(this.$refs.idreason.rejectReason.includes('自定义') && !this.$refs.idreason.rejectReasonSelfDefined){
                this.$message({
                  message: '请输入实名审核自定义原因',
                  type: 'error',
                });
                return;
              }
              params.identityReason = this.$refs.idreason.getTotalReason();
            }
          }
          if(this.alipayAuditFlag && !this.alipayDisabled){
            params.aliStatus = this.form.aliStatus;
            if(this.form.aliStatus  == 1){

              if(!this.$refs.alipayreason.rejectReason.length){
                this.$message({
                  message: '请选择支付宝审核拒绝原因',
                  type: 'error',
                });
                return;

              }
              if(this.$refs.alipayreason.rejectReason.includes('自定义') && !this.$refs.alipayreason.rejectReasonSelfDefined){
                this.$message({
                  message: '请输入支付宝审核自定义原因',
                  type: 'error',
                });
                return;
              }
              params.aliReason = this.$refs.alipayreason.getTotalReason();
            }
          }
          if(this.wechatAuditFlag && !this.wechatDisabled){
            params.wechatStatus = this.form.wechatStatus;
            if(this.form.wechatStatus  == 1){

              if(!this.$refs.wechatreason.rejectReason.length){
                this.$message({
                  message: '请选择微信审核拒绝原因',
                  type: 'error',
                });
                return;
              }
              if(this.$refs.wechatreason.rejectReason.includes('自定义') && !this.$refs.wechatreason.rejectReasonSelfDefined){
                this.$message({
                  message: '请输入微信审核自定义原因',
                  type: 'error',
                });
                return;
              }
              params.wechatReason = this.$refs.wechatreason.getTotalReason();
            }
          }
          if(this.usdtAuditFlag && !this.usdtDisabled){
            if(!this.form.usdtStatus){
              this.$message.error('请选择审核结果');
              return;
            }
            params.usdtStatus = this.form.usdtStatus;
            if(this.form.usdtStatus  == 1){

              if(!this.$refs.usdtreason.rejectReason.length){
                this.$message({
                  message: '请选择USDT审核拒绝原因',
                  type: 'error',
                });
                return;
              }
              if(this.$refs.usdtreason.rejectReason.includes('自定义') && !this.$refs.usdtreason.rejectReasonSelfDefined){
                this.$message({
                  message: '请输入USDT审核自定义原因',
                  type: 'error',
                });
                return;
              }
              params.usdtReason = this.$refs.usdtreason.getTotalReason();
            }
          }
          aduit(params).then(res => {
            this.resetForm()
            this.loading = false
            this.dialog = false
            this.$parent.init()
            this.$notify({
              title: '操作成功',
              type: 'success',
              duration: 2500
            })
          }).catch(err => {
            this.loading = false
            this.dialog = false
            this.form.status = 1
          })
        }else {

        }
      })

    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {}
    },
  }
}
</script>

<style lang="less" scoped>
.customer-audit {
  .id-img {
    width: 140px;
  }
  .pay-img {
    width: 80px;
  }
  .usdt-img {
    width: 100px;
  }
}
</style>
