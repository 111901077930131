import request from '@/utils/request'

export function aduit(data) {
    return request({
        url: 'community/crm/srCommunityIdInfo',
        method: 'put',
        data
    })
}

export function getReasonList() {
  return request({
    url: 'community/crm/getRejectionReason',
    method: 'get',
  })
}
